<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table 
                    striped
                    small
                    id="tariffTable"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :items="performerRequest"
                    :fields="fields"
                    @row-clicked="clicked"
                    @row-dblclicked="editForm"
                    head-row-variant="primary"
                    >
                </b-table>
            </b-card>
        </b-col>
        </b-row>
    </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js"
import { cellSelect } from "@/utils/TableFunctions/cellSelect";

export default {
    props: ['performerRequest'],
    data() {
        return {
            id: null,
            fields: [
            { key: 'id', label: 'ID', sortable: true, thStyle: {width: '50px'}},
            { key: 'name', label: 'Наименование', sortable: true, thStyle: {width: '50px'}},
            { key: 'code', label: 'Код', sortable: true, thStyle: {width: '50px'} },
            { key: 'parent_name', label: 'Имя родителя', sortable: true, thStyle: {width: '50px'} },
            { key: 'parent_id', label: 'Идентификатор родителя', sortable: true, thStyle: {width: '50px'} },
            { key: 'pre_assignment_order', label: 'Код', sortable: true, thStyle: {width: '50px'} },
        ],
        }
    },
    mounted(){
        resizeable()
    },
    methods: {
        editForm(item){
            let id = item.id
            this.$emit('editForm', id)
        },
        clicked(item, index, event){
            this.$store.state.filterPlus.tableIndex = index

            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                return;
            }

            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;
            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            cellSelect()
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
</style>